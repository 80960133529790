<template>
	
	<div class="dellys">
		<p>{{title}}</p>
		<div  v-html="cont" style="margin-bottom: 50px;text-align: justify;line-height: 300%;">
			
			
		</div>
		<p>资料来源：{{artsource}} </p>
		 <el-button @click="back()" style="margin-bottom: 10%;margin-left: 92.5%;width: 8%;height: 10%;text-align: center;align-items: center;"> 返回</el-button>
	</div>	

	
</template>

<script>
	export default {
		data(){
			return{
				cont:'',
				title:'',
				artsource:'',
				routerName:'',
				 titleNew:'',
				 hydtlistQuery:{
				  	current:1,
				  	pageSize:6,
				  	listMode:true,
				  	keyWord:'1',
					name:'sp'
				  },
				  jyxwlistQuery:{
				   	current:1,
				   	pageSize:6,
				   	listMode:true,
				   	keyWord:'2',
					name:'sp'
				   },
				   jkcslistQuery:{
				    	current:1,
				    	pageSize:6,
				    	listMode:true,
				    	keyWord:'3',
						name:'sp'
				    },
				 
			}
		},
		
	   created() {
		   
		  var dynamic=JSON.parse(this.$route.query.conten)
		        console.log(dynamic)
		  this.titleNew=dynamic.rotertitle
		   this.routerName=dynamic.routerName
		   this.cont=dynamic.dynamicContent
		   this.title=dynamic.title
		   this.artsource=dynamic.artsource
	},
	methods: {
	    back() {
			if(this.titleNew=='行业动态'){
			this.$router.push({name:this.routerName,query:{title:JSON.stringify(this.hydtlistQuery)  }})	
			}else if(this.titleNew=='九域新闻'){
			 this.$router.push({name:this.routerName,query:{title:JSON.stringify(this.jyxwlistQuery)  }})	
			}else if(this.titleNew=='健康常识'){
				this.$router.push({name:this.routerName,query:{title:JSON.stringify(this.jkcslistQuery)  }})		
			}
	        
	    },
	},
	mounted() {
		 window.scrollTo(0,0);
		 sessionStorage.setItem("msgInfo",true)
	}
	}
</script>

<style scoped>
	.dellys{
		margin: 0 auto;
		width: 70%;
		
	}
	p{
		font-weight: 600;
		font-size: 25px;
		margin-top: 40px;
		margin-bottom: 50px;
	}
	.backDiv{
		width: 1100px;
		text-align: right;
		margin-bottom: 50px;
	}
	.backImg{
		width: 140px;
		height: 45px;
		display: inline-block;
		cursor: pointer;
	}
	/deep/.MsoNormal{
		line-height: 300%!important;
	}
</style>
